<template>
  <div>
    <b-overlay
      :show="imageOverlay"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <vue-upload-multiple-image
        v-model="images"
        style="margin-top: 2px"
        :data-images="images"
        name="images"
        drag-text="Veuillez ajouter une image svp!"
        browse-text="Browse image"
        primary-text="Primary image"
        mark-is-primary-text="Set default image"
        :min-image="1"
        :showEdit="false"
        :max-image="1"
        popup-text="Description default image"
        :id-upload="item.id.toString()"
        @upload-success="uploadImageAndReturnSrc"
        @before-remove="beforeRemove"
      ></vue-upload-multiple-image>
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay } from "bootstrap-vue";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { uploadImageSuccess } from "./ProductSettings/FileUpload";
export default {
  components: {
    BOverlay,
    VueUploadMultipleImage,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      imageOverlay: false,
      images: [],
      id: null,
    };
  },
  methods: {
    async uploadImageAndReturnSrc(formData, index, fileList) {
      this.imageOverlay = true;
      try {
        const uploadedUrls = await uploadImageSuccess(
          formData,
          index,
          fileList
        );
        console.log(uploadedUrls);
        this.item.image = {};
        this.images = uploadedUrls;
        // this.images[0].id = this.id;
        this.images[0].name = "";
        this.images[0].default = 1;
        this.images[0].path = uploadedUrls[0].src;
        this.images[0].src = uploadedUrls[0].src;
        this.images[0].highlight = 1;
        this.item.image = this.images[0];
        this.imageOverlay = false;
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    },
    beforeRemove(index, done, fileList) {
      this.item.image = [];

      this.id = this.images[0].id;
      var r = confirm("remove image");
      if (r == true) {
        var indexInArrayImages = this.images.indexOf(fileList[index].path);
        if (index > -1) {
          this.images.splice(indexInArrayImages, 1);
        }

        done();
      }
    },
    transformImagesUploadToImagesArray(imagesUploadArray) {
      const transformedArray = imagesUploadArray.map((item) => ({
        name: "",
        path: item.src,
        highlight: 1,
        default: 1,
      }));

      return transformedArray;
    },
  },
  created() {
    if (this.item.image) {
      this.item.image.name = "";
      this.item.image.default = 1;
      this.item.image.path = this.item.image.src;
      this.item.image.highlight = 1;
      this.images.push(this.item.image);
    }
  },
};
</script>

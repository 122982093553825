<
<template>
  <div class="app-ecommerce">
    <!-- Add Product -->

    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div
        class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-3"
      >
        <div class="d-flex flex-column justify-content-center">
          <h4 class="mb-1 mt-3">Modifier le produit : {{ title }}</h4>
          <p class="text-muted">
            Permalien :
            <a :href="product.permalink" target="_blank">
              {{ product.permalink }}
            </a>
          </p>
        </div>
        <div class="d-flex align-content-center flex-wrap gap-3">
          <div class="d-flex gap-3">
            <button
              type="submit"
              class="btn btn-primary"
              @click="showMsgBoxTwo"
            >
              Publier
            </button>
          </div>

          <button
            type="submit"
            class="btn btn-secondary ml-3"
            @click="DiscardModification"
          >
            Annuler
          </button>
        </div>
      </div>
      <div class="row">
        <!-- First column-->
        <div class="col-12 col-lg-8">
          <!-- Product Information -->
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-tile mb-0">Product information</h5>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <label class="form-label" for="ecommerce-product-name"
                  >Name</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="product.name"
                  placeholder="Product title"
                  name="productTitle"
                  aria-label="Product title"
                />
              </div>
              <div class="row mb-3">
                <div class="col">
                  <label class="form-label" for="ecommerce-product-sku"
                    >SKU</label
                  >
                  <input
                    class="form-control"
                    v-model="product.sku"
                    placeholder="SKU"
                    name="productSku"
                    aria-label="Product SKU"
                  />
                </div>
              </div>
              <!-- Description -->
              <div>
                <H5 class="form-label">Description </H5>
                <quill-editor v-model="product.description" />
              </div>
              <div class="mt-2">
                <H5 class="form-label"> Short Description </H5>
                <quill-editor v-model="product.short_description" />
              </div>
            </div>
          </div>
          <!-- /Product Information -->

          <!-- /Media -->
        </div>
        <!-- /Second column -->

        <!-- Second column -->
        <div class="col-12 col-lg-4">
          <!-- /Pricing Card -->
          <!-- Organize Card -->
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-title mb-0">Categories</h5>
              <feather-icon
                style="color: #19b4bf; cursor: pointer"
                icon="RefreshCcwIcon"
                size="20"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                v-b-tooltip.hover.v-dark
                title="Refresh category list"
                variant="gradient-primary"
                @click="refrechCategory()"
              />
            </div>
            <div class="card-body">
              <div class="mb-3">
                <v-select
                  style="cursor: pointer"
                  :loading="categoriesLoader"
                  v-model="product.categories"
                  :close-on-select="false"
                  :options="categories"
                  multiple
                  label="name"
                  input-id="id"
                  value="id"
                />
              </div>
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-title mb-0">Marques</h5>
              <feather-icon
                style="color: #19b4bf; cursor: pointer"
                icon="RefreshCcwIcon"
                size="20"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                v-b-tooltip.hover.v-dark
                title="Refresh category list"
                variant="gradient-primary"
                @click="refrechCategory()"
              />
            </div>
            <div class="card-body">
              <div class="mb-3">
                <v-select
                  style="cursor: pointer"
                  :loading="loadingBrands"
                  v-model="product.brands"
                  :close-on-select="false"
                  :options="brands"
                  multiple
                  label="name"
                  input-id="id"
                  value="id"
                >
                </v-select>
              </div>
            </div>
          </div>
          <!-- Media -->
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-title mb-0">Media</h5>
            </div>
            <div class="card-body">
              <div class="row mb-3">
                <b-overlay
                  :show="imageOverlay"
                  spinner-variant="primary"
                  spinner-type="grow"
                  spinner-small
                  rounded="sm"
                >
                  <vue-upload-multiple-image
                    :showEdit="false"
                    v-model="images"
                    :data-images="images"
                    name="images"
                    drag-text="Veuillez ajoutez une images svp!"
                    browse-text="Browse image"
                    primary-text=""
                    mark-is-primary-text=""
                    :min-image="1"
                    popup-text="Description default image"
                    drop-text="Drag and drop"
                    @upload-success="uploadImageAndReturnSrc"
                    @before-remove="beforeRemove"
                  >
                  </vue-upload-multiple-image>
                </b-overlay>
                <div class="col">
                  <div class="mb-3"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Organize Card -->
        </div>

        <EditProductVariantForm
          :variant="variationsProduit"
          :selectedAttribute="product.attributes[0]"
          :attribute="attribute"
          :productId="productId"
        />
      </div>
    </b-overlay>
  </div>
</template>
<script>
import EditProductVariantForm from "./EditProductVariantForm .vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import axios from "axios";
import Cleave from "vue-cleave-component";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import websiteURL from "@/websiteURL";
import {
  BTabs,
  BTab,
  BCardText,
  BOverlay,
  BFormSelect,
  BFormDatepicker,
  VBTooltip,
} from "bootstrap-vue";
import ProductSettingPrice from "./ProductSettings/ProductSettingPrice.vue";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import Ripple from "vue-ripple-directive";
import { useCurrencyInput } from "vue-currency-input";
import { uploadImageSuccess } from "./ProductSettings/FileUpload"; // Import the function
import {
  getAttributeTerms,
  getBrands,
  getParentCategories,
} from "../../services/products/Products"; // Import the Service

export default {
  components: {
    EditProductVariantForm,
    VBTooltip,
    BFormSelect,
    vSelect,
    useCurrencyInput,
    Cleave,
    BOverlay,
    BCardText,
    BTabs,
    BTab,
    ProductSettingPrice,
    quillEditor,
    VueUploadMultipleImage,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      brands: [],
      variationsProduit: [],
      attribute: [],
      title: null,
      locale: "fr",
      data: {
        selectedDate: new Date(),
      },
      selected: { title: "Square" },
      option: [
        { title: "Square" },
        { title: "Rectangle" },
        { title: "Rombo" },
        { title: "Romboid" },
      ],
      selectedCategory: [],
      imageOverlay: false,
      product: {
        images: [],
        categories: [],
      },
      content: "",
      options: {},
      boxOne: "",
      boxTwo: "",
      loading: false,
      permalink: "",
      images: [],
      imagesUrl: [],
      regular_price: "",
      sale_price: "",
      placeholder: " ",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: false,
      valueWhenIsEmpty: "",
      options: {
        locale: "fr-TN",
        prefix: "DT",
        suffix: "",
        length: 11,
        max: 50000,
        precision: 3,
      },
      price: "",
      categories: [],
      selectedCategories: [],
      categoriesLoader: false,
      loadingBrands: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    const productId = to.params.id;
    const apiUrl = `${websiteURL.WEBSITE}wp-json/wc/v3/products/${productId}`;

    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;

    try {
      const response = await axios.get(apiUrl, {
        auth: {
          username: consumerKey,
          password: consumerSecret,
        },
      });

      next((vm) => {
        vm.product = response.data;
        vm.product.variations = response.data.variations;
        vm.imagesUpload = response.data.images;
        vm.images = vm.transformImagesUploadToImagesArray(vm.imagesUpload);
        vm.productId = response.data.id;
        vm.productName = response.data.name;
        vm.productDescription = response.data.description;
        vm.short_description = response.data.short_description;
        vm.category = response.data.categories;
        vm.sku = response.data.sku;
        vm.regular_price = response.data.regular_price;
        vm.sale_price = response.data.sale_price;
        vm.date_on_sale_from = response.data.date_on_sale_from;
        vm.date_on_sale_to = response.data.date_on_sale_to;
        vm.on_sale = response.data.on_sale;
        vm.title = vm.product.name;

        // Fetch variations
        for (let i = 0; i <= vm.product.variations.length; i++) {
          const variation = vm.product.variations[i];

          const variationApiUrl = `${websiteURL.WEBSITE}wp-json/wc/v3/products/${productId}/variations/${variation}`;

          axios
            .get(variationApiUrl, {
              auth: {
                username: consumerKey,
                password: consumerSecret,
              },
            })

            .then((variationResponse) => {
              vm.product.variations.splice(i, 1);
              vm.variationsProduit.push(variationResponse.data);
            })
            .catch((error) => {});
        }
      });
    } catch (error) {
      console.error("Error fetching product details:", error);
      next(false); // You can pass `false` to indicate that the route should not be entered.
    }
  },

  methods: {
    async refrechBands() {
      this.loadingBrands = true;
      this.brands = await getBrands();
      this.loadingBrands = false;
    },
    DiscardModification() {
      this.$router.push({
        name: "product",
      });
    },
    beforeRemove(index, done, fileList) {
      var r = confirm("remove image");
      if (r == true) {
        var indexInArrayImages = this.product.images.indexOf(
          fileList[index].path
        );
        if (index > -1) {
          this.product.images.splice(indexInArrayImages, 1);
        }

        done();
      }
    },
    transformImagesUploadToImagesArray(imagesUploadArray) {
      const transformedArray = [];

      imagesUploadArray.forEach((item) => {
        transformedArray.push({
          name: "",
          path: item.src,
          highlight: 1,
          default: 1,
        });
      });

      return transformedArray;
    },
    async uploadImageAndReturnSrc(formData, index, fileList) {
      this.imageOverlay = true;
      try {
        const uploadedUrls = await uploadImageSuccess(
          formData,
          index,
          fileList
        );
        this.product.images.push(...uploadedUrls);

        console.log("Uploaded Image Source:", this.product.images);
        this.imageOverlay = false;
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    },
    showMsgBoxTwo() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(
          "Veuillez confirmer que vous souhaitez modifier le produit.",
          {
            title: "Veuillez confirmer",
            size: "sm",
            okVariant: "primary",
            okTitle: this.loading ? "Loading..." : "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          this.boxTwo = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.ModifierProduit().then(() => {
                this.loading = false;
                this.makeToast("info");
              });
            }
          }
        });
    },
    makeToast(variant = null) {
      this.$bvToast.toast("product added successfully click to view", {
        title: `Product ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
    async ModifierProduit() {
      if (this.sale_price) {
        this.product.sale_price = this.sale_price.toString();
      }
      if (this.regular_price) {
        this.product.regular_price = this.regular_price.toString();
      }

      this.variant = this.variationsProduit
        .filter((item) => item.selectedAttribute && item.terms)
        .map((item) => {
          const { selectedAttribute, terms } = item;
          return {
            ...item,
            attributes: [
              {
                id: selectedAttribute.id,
                option: terms.name,
              },
            ],
          };
        });
      const productAttributes = [];
      this.variant.forEach((item) => {
        const { selectedAttribute, terms } = item;

        const existingAttribute = productAttributes.find(
          (attr) => attr.id === selectedAttribute.id
        );

        if (!existingAttribute) {
          const newAttribute = {
            id: selectedAttribute.id,
            position: 0,
            visible: true,
            variation: true,
            options: [terms.name],
          };

          productAttributes.push(newAttribute);
        } else {
          existingAttribute.options.push(terms.name);
        }
      });

      this.product.type = "variable";
      this.product.manage_stock = true;
      try {
        const apiUrl = `${websiteURL.WEBSITE}wp-json/wc/v3/products/${this.productId}`;
        const consumerKey = websiteURL.CONSUMERKEY;
        const consumerSecret = websiteURL.CONSUMERSECRET;
        const productResponse = await axios.put(apiUrl, this.product, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });

        const productId = productResponse.data.id;
        const variationResponses = [];

        for (const variation of this.variationsProduit) {
          const variationApiUrl = `${websiteURL.WEBSITE}wp-json/wc/v3/products/${this.productId}/variations/${variation.id}`;

          const variationResponse = await axios.put(
            variationApiUrl,
            variation,
            {
              auth: {
                username: consumerKey,
                password: consumerSecret,
              },
            }
          );
          variationResponses.push(variationResponse.data);
          this.permalink = productResponse.data.permalink;
        }
      } catch (error) {
        console.error("Error adding product and variations:", error);
        throw error;
      }
    },
    stripTheGarbage(e) {
      if ((e.keyCode < 48 && e.keyCode !== 46) || e.keyCode > 59) {
        e.preventDefault();
      }
    },
    formatDinar() {
      if (this.price != "") {
        var num = this.price;

        num = Number(num);

        var countDecimals = function (value) {
          if (Math.floor(value) === value) return 0;
          return value.toString().split(".")[1].length || 0;
        };

        var decimal = countDecimals(num);

        if (decimal < 2) {
          num = num.toFixed(2);
        }

        if (decimal > 2) {
          num = num.toFixed(decimal);
        }

        if (parseInt(num) < 1) {
          num = "." + String(num).split(".")[1];
        }

        this.price = num;
      }
    },
    async refrechCategory() {
      this.categoriesLoader = true;
      this.categories = await getParentCategories();
      this.categoriesLoader = false;
    },
  },
  async created() {
    this.categoriesLoader = true;
    this.loadingBrands = true;
    this.categories = await getParentCategories();
    this.categoriesLoader = false;
    this.brands = await getBrands();
    this.loadingBrands = false;
    this.attribute = await getAttributeTerms();
  },
};
</script>
<style lang="scss">
// Style
.ql-snow {
  &.ql-container {
    .ql-editor {
      min-height: 200px;
    }
  }
}
.currency-input {
  position: relative;

  input {
    padding-left: 3rem;
    width: 100%;
  }

  .currency-symbol {
    position: absolute;
    top: 50%;
    left: 0;
    width: 3rem;
    text-align: center;
    transform: translateY(-50%);
  }

  input:invalid ~ .currency-symbol {
    opacity: 0.5;
  }
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<template>
  <div class="col-12 col-lg-12">
    <div class="card mb-4" v-if="variant.length > 0">
      <div class="card-header">
        <h5 class="card-title mb-0">Variants</h5>
      </div>

      <b-col cols="12" lg="3">
        <label class="d-inline">Attribut:</label>

        <v-select
          v-model="selectedAttribute"
          :options="attribute"
          label="name"
          :clearable="false"
          class="mb-2 item-selector-title"
          placeholder="Select Attribut"
          :disabled="true"
        />
      </b-col>
      <b-card-body class="invoice-padding form-item-section">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <div ref="form" class="repeater-form" :style="{ height: trHeight }">
            <b-row
              v-for="(item, index) in variant"
              :key="index"
              ref="row"
              class="pb-2"
            >
              <b-col cols="12">
                <div class="d-none d-lg-flex">
                  <b-row class="flex-grow-1 px-1"> </b-row>
                </div>
                <div class="d-flex border rounded">
                  <b-row class="flex-grow-1 p-2">
                    <b-col cols="12" lg="3">
                      <label class="d-inline">Terme:</label>

                      <v-select
                        placeholder="Select Terme"
                        v-model="item.attributes[0].option"
                        label="name"
                        :options="getAvailableTerms(selectedAttribute)"
                        class="mb-2 item-selector-title"
                        :disabled="true"
                      />
                    </b-col>
                    <b-col cols="12" lg="3">
                      <label class="d-inline">SKU:</label>
                      <b-form-input
                        v-model="item.sku"
                        placeholder="SKU"
                        class="mb-2"
                      />
                    </b-col>
                    <b-col cols="12" lg="3">
                      <label class="d-inline">Sale quantity:</label>
                      <b-form-input
                        v-model="item.stock_quantity"
                        placeholder="Sale quantity"
                        class="mb-2"
                      />
                    </b-col>
                    <b-col cols="12" lg="3">
                      <label class="form-label" for="ecommerce-product-price"
                        >Base Price</label
                      >
                      <div class="currency-input">
                        <form action="">
                          <input
                            class="form-control"
                            type="text"
                            name="price"
                            v-model="item.regular_price"
                            @keypress="stripTheGarbage($event)"
                            required
                          />
                          <span class="currency-symbol">TND </span>
                        </form>
                      </div>
                    </b-col>
                    <b-col cols="12" lg="3">
                      <label
                        class="form-label"
                        for="ecommerce-product-discount-price"
                        >Discounted Price</label
                      >
                      <div class="currency-input">
                        <form action="">
                          <input
                            class="form-control"
                            type="text"
                            name="price"
                            v-model="item.sale_price"
                            required
                          />
                          <span class="currency-symbol">TND </span>
                        </form>
                      </div>
                    </b-col>
                    <b-col cols="12" lg="6"> </b-col>
                    <b-col cols="12" lg="9">
                      <div>
                        <label class="d-inline">Description :</label>
                        <quill-editor v-model="item.description" />
                      </div>
                    </b-col>

                    <b-col cols="12" lg="3">
                      <label class="d-inline"> Image produit :</label>
                      <uploadImage :item="item" />
                    </b-col>

                    <b-col cols="12" lg="10"> </b-col>
                    <b-col cols="12" lg="2">
                      <button
                        style="margin-left: 80px"
                        type="submit"
                        class="btn btn-primary"
                        @click="saveVariation(item)"
                      >
                        Enregistrer
                      </button>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </div>
          <!--   <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          :disabled="!selectedAttribute"
          variant="primary"
          @click="addNewItemInItemForm"
        >
          Add Item
        </b-button> -->
        </b-overlay>
      </b-card-body>
    </div>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Cleave from "vue-cleave-component";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";

import {
  BFormTextarea,
  BFormGroup,
  BRow,
  BForm,
  BFormInput,
  BCol,
  BButton,
  BCard,
  BTabs,
  BTab,
  BCardText,
  BPopover,
  BCardBody,
  BOverlay,
  BFormSelect,
  BFormDatepicker,
  VBTooltip,
} from "bootstrap-vue";
import ProductSettingPrice from "./ProductSettings/ProductSettingPrice.vue";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import uploadImage from "./EdituploadImage.vue";
import Ripple from "vue-ripple-directive";
import { heightTransition } from "@core/mixins/ui/transition";
import { useCurrencyInput } from "vue-currency-input";
import websiteURL from "@/websiteURL";
import axios from "axios";
export default {
  props: {
    selectedAttribute: Object,
    variant: Array,
    attribute: Array,
    productId: Number,
  },
  components: {
    uploadImage,
    BFormTextarea,
    BCol,
    BButton,
    BFormInput,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BCol,
    BButton,
    BCard,
    VBTooltip,
    BFormSelect,
    vSelect,
    useCurrencyInput,
    Cleave,
    BPopover,
    BOverlay,
    BCardBody,
    BCardText,
    BTabs,
    BTab,
    ProductSettingPrice,
    quillEditor,
    VueUploadMultipleImage,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  mixins: [heightTransition],
  data() {
    return {
      itemFormBlankItem: {},
      loading: false,
      nextTodoId: 2,
      planifier: false,
      locale: "fr",
    };
  },

  methods: {
    async saveVariation(item) {
      this.loading = true;

      const data = {};
      data.sku = item.sku;
      data.image = item.image;
      data.regular_price = item.regular_price;
      data.stock_quantity = item.stock_quantity;
      data.sale_price = item.sale_price;
      data.description = item.description;
      data.date_on_sale_from = item.date_on_sale_from;
      data.date_on_sale_to = item.date_on_sale_to;

      const variationApiUrl = `${websiteURL.WEBSITE}wp-json/wc/v3/products/${this.productId}/variations/${item.id}`;

      const variationResponse = await axios.put(variationApiUrl, data, {
        auth: {
          username: websiteURL.CONSUMERKEY,
          password: websiteURL.CONSUMERSECRET,
        },
      });

      this.loading = false;
    },
    getAvailableTerms(selectedAttribute) {
      if (selectedAttribute) {
        const availableTerms = selectedAttribute.options.filter((term) => {
          return this.attribute.every(
            (item) => item === this.item || item.terms !== term
          );
        });

        return availableTerms;
      }
      return this.attribute;
    },
    addNewItemInItemForm() {
      this.itemFormBlankItem.selectedAttribute = this.selectedAttribute;
      this.itemFormBlankItem.index = this.variant.length;
      this.itemFormBlankItem.attributes = [
        { id: null, name: null, option: null },
      ];
      this.itemFormBlankItem.image = {};

      this.$refs.form.style.overflow = "hidden";
      this.variant.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)));

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    stripTheGarbage(e) {
      if ((e.keyCode < 48 && e.keyCode !== 46) || e.keyCode > 59) {
        e.preventDefault();
      }
    },
    formatDinar() {
      if (this.price != "") {
        var num = this.price;
        num = Number(num);
        var countDecimals = function (value) {
          if (Math.floor(value) === value) return 0;
          return value.toString().split(".")[1].length || 0;
        };
        var decimal = countDecimals(num);
        if (decimal < 2) {
          num = num.toFixed(2);
        }
        if (decimal > 2) {
          num = num.toFixed(decimal);
        }
        if (parseInt(num) < 1) {
          num = "." + String(num).split(".")[1];
        }
        this.price = num;
      }
    },

    repeateAgain() {
      this.variant.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },

  async created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  mounted() {
    this.initTrHeight();
  },
};
</script>
<style lang="scss">
// Style
.ql-snow {
  &.ql-container {
    .ql-editor {
      min-height: 200px;
    }
  }
}
.currency-input {
  position: relative;

  input {
    padding-left: 3rem;
    width: 100%;
  }

  .currency-symbol {
    position: absolute;
    top: 50%;
    left: 0;
    width: 3rem;
    text-align: center;
    transform: translateY(-50%);
  }

  input:invalid ~ .currency-symbol {
    opacity: 0.5;
  }
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
